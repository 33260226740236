import type {
  InsuranceOffer,
  InsuranceOfferWithSelectedState,
} from '@backmarket/http-api'

export function hasOfferCompliancyError(
  insuranceOffer: InsuranceOfferWithSelectedState,
) {
  return (
    insuranceOffer.selected &&
    insuranceOffer.compliancy.isUserAgreementRequired &&
    !insuranceOffer.compliancy.isUserAgreementAccepted
  )
}

export function isDefaultOfferSelected(
  insuranceOffer: InsuranceOfferWithSelectedState,
) {
  return insuranceOffer.defaultOffer && insuranceOffer.selected
}

export function hasInsuranceSelected(
  insuranceOffers: InsuranceOfferWithSelectedState[],
) {
  return !insuranceOffers.some(isDefaultOfferSelected)
}

export function getOfferWithoutSelectedState(
  insuranceOffer: InsuranceOfferWithSelectedState,
): InsuranceOffer {
  const { selected, ...offerWithoutSelectedState } = insuranceOffer

  return offerWithoutSelectedState
}
