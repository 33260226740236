export default {
  description: {
    id: 'insurances_options_terms_description',
    defaultMessage:
      'By purchasing this insurance plan, I have read and accept the {terms, html}, {ipid, html}, {recap, html}.',
  },
  terms: {
    id: 'insurances_options_terms_terms',
    defaultMessage: 'Terms and Conditions',
  },
  ipid: {
    id: 'insurances_options_terms_ipid',
    defaultMessage: 'Insurance Product Information Document',
  },
  recap: {
    id: 'insurances_options_terms_recap',
    defaultMessage: 'Backmarket Recap',
  },
}
