<template>
  <div v-if="!offer.defaultOffer">
    <RevCheckbox
      v-if="offer.compliancy.isUserAgreementRequired"
      :id
      :model-value="offer.compliancy.isUserAgreementAccepted"
      @update:model-value="handleCheck"
    >
      <template #label>
        <Terms :documents="offer.compliancy.documents" />
      </template>
    </RevCheckbox>

    <Terms v-else :documents="offer.compliancy.documents" />
  </div>
</template>

<script setup lang="ts">
import type { InsuranceOffer } from '@backmarket/http-api'
import { RevCheckbox } from '@ds/components/Checkbox'

import Terms from './components/Terms/Terms.vue'

const props = defineProps<{
  id: string
  offer: InsuranceOffer
}>()

const emit = defineEmits<{
  update: [updatedInsurance: InsuranceOffer]
}>()

function handleCheck(isUserAgreementAccepted: boolean | string[]) {
  if (typeof isUserAgreementAccepted !== 'boolean') return

  emit('update', {
    ...props.offer,
    compliancy: { ...props.offer.compliancy, isUserAgreementAccepted },
  })
}
</script>
